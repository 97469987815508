import { isDevMode, NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FlooriWebConfigModule } from '@floori-web/config';
import {
    AppFacade,
    AppState,
    cookiesReducer,
    CookiesEffects,
    ErrorEffects,
    errorsReducer,
    integrationReducer,
    routerReducer,
    splashReducer,
    VisitorEffects,
    visitorReducer,
} from '@floori-web/store';
import { RouterModule } from '@angular/router';
import { ResolversModule } from '@floori-web/utils/resolvers/src';
import { HttpClientModule } from '@angular/common/http';
import { StorageModule } from '@floori-web/storage';
import { CqrsModule } from '@floori-web/cqrs';
import { TrackingModule } from '@floori-web/tracking';
import { FlooriDialogModule, TranslationsModule } from '@floori-web-components';
import { NavigationModule } from '@floori-web/navigation';
import { DraftModeModule } from '@floori-web/draft-mode';
import {
    flooriErrorHandler,
    flooriTraceProvider,
} from '../../../../shared/api/src/lib/global-error.handler';
import { appInitializerProvider } from './app-initializer/app.initializer';
import { appRoutes } from './routing/app.routing';
import { layoutProvider, navigationStoreProvider } from './providers';

const imports = [
    RouterModule.forRoot([...appRoutes], {
        initialNavigation: 'enabledBlocking',
    }),
    NavigationModule,
    FlooriDialogModule,
    TranslationsModule.forRoot(),
    CqrsModule.forRoot(),
    StoreModule.forRoot<AppState>(
        {
            cookies: cookiesReducer,
            splash: splashReducer,
            visitor: visitorReducer,
            router: routerReducer,
            error: errorsReducer,
            integration: integrationReducer,
        },
        {
            runtimeChecks: {
                strictActionTypeUniqueness: true,
            },
        },
    ),
    EffectsModule.forRoot([VisitorEffects, ErrorEffects, CookiesEffects]),
    StorageModule,
    ResolversModule,
    FlooriWebConfigModule,
    TrackingModule.forRoot(),
    DraftModeModule,
];

if (isDevMode()) {
    imports.push(
        StoreDevtoolsModule.instrument({
            name: 'floori',
        }),
    );
}

@NgModule({
    imports,
    exports: [RouterModule, TranslationsModule, HttpClientModule, DraftModeModule],
    providers: [
        AppFacade,
        appInitializerProvider,
        flooriErrorHandler,
        flooriTraceProvider,
        layoutProvider,
        navigationStoreProvider,
    ],
    declarations: [],
})
export class FlooriWebShellModule {}
