import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { takeUntil, tap } from 'rxjs';
import { FlooriControl } from '../../utils';

let uniqueId = 0;

@Component({
    selector: 'floori-checkbox',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './floori-checkbox.component.html',
    styleUrls: ['./floori-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FlooriCheckboxComponent,
        },
    ],
})
export class FlooriCheckboxComponent extends FlooriControl implements ControlValueAccessor {
    private readonly _uniqueId: string;
    private readonly idPrefix = 'floori-checkbox';
    private _checked = false;
    @Input() id: string;
    @Input() align: 'start' | 'end' = 'start';
    @Output() checkedChanged = new EventEmitter<boolean>();

    @Input() get checked(): boolean {
        return this._checked;
    }

    set checked(val: BooleanInput) {
        const checked = coerceBooleanProperty(val);
        if (checked !== this.checked) {
            this._checked = checked;
            this.onTouch(this._checked);
            this.onChange(this._checked);
            this.checkedChanged.emit(this._checked);
            this.cdr.markForCheck();
        }
    }

    @HostBinding('class.floori-checkbox') hostClass = true;

    @HostBinding('class.align-checkbox-end')
    get alignBinding(): boolean {
        return this.align === 'end';
    }

    get inputId(): string {
        return this.id || this._uniqueId;
    }

    constructor() {
        super();
        this.id = this._uniqueId = `${this.idPrefix}-${++uniqueId}`;
    }

    writeValue(value: boolean): void {
        if (this.onChangeInit) {
            this.checked = value;
        }
    }

    protected override setupControl(): void {
        super.setupControl();
        this.control?.statusChanges
            ?.pipe(
                takeUntil(this.destroyed),
                tap(() => {
                    this._invalid = this.control?.pristine
                        ? false
                        : this.required && !this.control?.value;
                    this.cdr.markForCheck();
                }),
            )
            .subscribe();
    }
}
