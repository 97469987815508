import { screenBreakpoints } from './screen-breakpoints';

export const flooriConstants = {
    flooriLogo: 'assets/floori_horizontal.svg',
    flooriLogoVertical: 'assets/floori_vertical.svg',
    validImagesRegex: /([/\\.\-\w\s])*\.(?:jpg|jpeg|png)/i,
    visitorIdKey: 'visitorId',
    screenBreakpoints,
    cssVarPrefix: 'flc-',
    phoneFormat: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
    postalCode: /\\d*\\-\\d*/,
    emailFormat:
        // Cannot change length of this format as well as split it.
        // eslint-disable-next-line max-len
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    products: {
        pageLimit: 12,
    },
} as const;
