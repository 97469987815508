import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NgControl,
    NgModel,
    Validators,
} from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgDestroyed } from './ng-destroyed';

@Directive()
export abstract class FlooriControl extends NgDestroyed implements OnInit {
    @ViewChild('input') input?: ElementRef;

    @Output() readonly focusChange = new EventEmitter<boolean>();
    protected readonly focusedClassName = 'focus';
    protected readonly invalidClassName = 'invalid';
    protected _disabled = false;
    protected _invalid = false;
    protected _readonly = false;
    protected _required = false;
    protected cdr = inject(ChangeDetectorRef);
    protected injector = inject(Injector);
    protected control?: FormControl;
    protected focused = false;
    protected ngControl?: NgControl;
    protected onChangeInit = false;

    @Input() set disabled(val: BooleanInput) {
        const parsedVal = coerceBooleanProperty(val);
        if (this._disabled !== parsedVal) {
            this._disabled = parsedVal;
            if (this._disabled) {
                this.focused = false;
            }
            this.cdr.markForCheck();
        }
    }

    get disabled(): boolean {
        if (this.control && this.control?.disabled !== null) {
            return !!this.control?.disabled;
        }
        return this._disabled;
    }

    @Input() set readonly(val: BooleanInput) {
        const parsedVal = coerceBooleanProperty(val);
        if (this._readonly !== parsedVal) {
            this._readonly = parsedVal;
            this.cdr.markForCheck();
        }
    }

    get readonly(): boolean {
        return this._readonly;
    }

    @Input() set required(val: BooleanInput) {
        const parsedVal = coerceBooleanProperty(val);
        if (this._required !== parsedVal) {
            this._required = parsedVal;
            this.cdr.markForCheck();
        }
    }

    get required(): boolean {
        if (this._required) {
            return true;
        }

        return (
            !!this.control?.hasValidator(Validators.required) ||
            !!this.control?.hasValidator(Validators.requiredTrue)
        );
    }

    @HostBinding('class')
    get classBindings(): string[] {
        return [this.focusedCls, this.invalidCls].filter(c => !!c);
    }

    private get invalidCls(): string {
        return this._invalid ? this.invalidClassName : '';
    }

    private get focusedCls(): string {
        return this.focused ? this.focusedClassName : '';
    }

    ngOnInit(): void {
        this.setupControl();
    }

    onChange: any = () => {};
    onTouch: any = () => {};

    focusChanged(focused: boolean): void {
        if (this.focused && !focused) {
            this.onTouch();
        }
        this.focused = focused;
        this.focusChange.emit(focused);
        this.cdr.markForCheck();
    }

    registerOnChange(fn: unknown): void {
        this.onChange = fn;
        this.onChangeInit = true;
    }

    registerOnTouched(fn: unknown): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    protected setupControl(): void {
        try {
            this.ngControl = this.injector?.get(NgControl);
        } catch (e) {
            return;
        }
        switch (this.ngControl.constructor) {
            case NgModel:
                const { control } = this.ngControl as NgModel;
                this.control = control;
                break;
            case FormControlName:
                this.control = this.injector
                    .get(FormGroupDirective)
                    ?.getControl(this.ngControl as FormControlName);
                break;

            default:
                this.control = (this.ngControl as FormControlDirective).form as FormControl;
                break;
        }
    }
}
