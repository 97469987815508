import { Inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import {
    TranslationsStorage,
    TranslationsStorageKeys,
} from '../models/translations-storage.provider';
import {
    isoLanguages,
    isoToFlooriLangs,
    TranslationsInitConfig,
    TranslationsProvider,
} from '../models';
import {
    FieldTranslation,
    FLOORI_STORAGE_PROVIDER,
    FlooriWindow,
    HashMap,
    Languages,
} from '../../models';
import { FLOORI_WINDOW } from '../../constants';

@Injectable()
export class FlooriTranslationService implements TranslationsProvider {
    readonly langChange$ = this.translocoService.langChanges$.pipe(distinctUntilChanged());

    get currentLang(): Languages {
        const isoLang = this.currentLangIso;
        return isoToFlooriLangs[isoLang] as Languages;
    }

    get currentLangIso(): string {
        return this.translocoService.getActiveLang();
    }

    constructor(
        private readonly translocoService: TranslocoService,
        @Inject(FLOORI_WINDOW) private readonly windowRef: FlooriWindow,
        @Inject(FLOORI_STORAGE_PROVIDER) private readonly storageProvider: TranslationsStorage,
    ) {}

    init(config: TranslationsInitConfig): Observable<void> {
        let savedLang = this.storageProvider.getSync(TranslationsStorageKeys.visitorLang);
        savedLang = config.languages.includes(savedLang as Languages) ? savedLang : '';
        const usersLang = savedLang || config.currentLang || Languages.en;

        if (config.languages.length === 0) {
            this.translocoService.setAvailableLangs([isoLanguages[usersLang]]);
        } else {
            this.translocoService.setAvailableLangs(
                config.languages.map(lang => isoLanguages[lang]) || [],
            );
        }

        this.translocoService.setDefaultLang(isoLanguages[usersLang] || (usersLang as string));
        this.translocoService.setActiveLang(isoLanguages[usersLang] || (usersLang as string));

        return this.translocoService
            .load(isoLanguages[usersLang] || (usersLang as string))
            .pipe(map(() => void 0));
    }

    changeLanguage(lang: Languages): Observable<Languages | undefined> {
        if (!lang) {
            return of(undefined);
        }

        return this.translocoService.load(isoLanguages[lang] || lang).pipe(
            map(() => {
                this.translocoService.setActiveLang(isoLanguages[lang] || lang);
                return (isoLanguages[lang] as Languages) || lang;
            }),
        );
    }

    translate(key: string | FieldTranslation, params?: HashMap, lang?: string | Languages): string {
        if (typeof key === 'string') {
            return this.translocoService.translate(key, params, lang);
        }

        const translationIso = key[lang as keyof FieldTranslation] ?? undefined;
        const flooriLang = isoToFlooriLangs[lang || ''];
        const translationFloori = key[flooriLang as keyof FieldTranslation] ?? undefined;

        const translation = translationFloori || translationIso || key.default;

        return typeof translation === 'string' ? translation : key.default;
    }
}
