import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriIconComponent } from '../floori-icon';
import { FlooriSkeletonLoaderDirective } from '../../directives';
import { HashMap, LabeledValue } from '../../models';

@Component({
    selector: 'floori-chips',
    standalone: true,
    imports: [CommonModule, FlooriIconComponent, FlooriSkeletonLoaderDirective],
    templateUrl: './floori-chips.component.html',
    styleUrls: ['./floori-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriChipsComponent<T> {
    @Input() loading = false;
    @Input() multi = false;
    @Input() showClear = false;
    @Output() readonly selectionChanged = new EventEmitter<string[]>();

    private readonly cdr = inject(ChangeDetectorRef);
    private _items: LabeledValue[] = [];
    private _selected: HashMap<string> = {};

    @Input() set items(val: LabeledValue[]) {
        this._items = [...val];
        this.cdr.markForCheck();
    }

    get items(): LabeledValue[] {
        return this._items;
    }

    @Input() set selected(val: string[]) {
        val?.forEach(v => (this._selected[v] = v));
        this.cdr.markForCheck();
    }

    get selectedMap(): HashMap<string> {
        return this._selected;
    }

    @HostBinding('class.floori-chips') hostClass = true;

    trackBy(index: number, item: LabeledValue): string {
        return item.key;
    }

    onChipClick(item: LabeledValue): void {
        if (this.multi) {
            this.selectedMap[item.value] = !!this.selectedMap[item.value] ? '' : item.value;
        } else {
            const newVal = this.selectedMap[item.value] ? '' : item.value;
            this._selected = { [item.value]: newVal };
        }
        const selectedItems = Object.keys(this.selectedMap).filter(k => !!this.selectedMap[k]);
        this.selectionChanged.emit(selectedItems);
    }
}
