import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationsModule } from '../../translations';
import { FlooriIconComponent } from '../floori-icon';
import { FLOORI_DIALOG_DATA, FlooriDialogModule } from '../floori-dialog';
import { FlooriButtonComponent } from '../floori-button';
import { PopupPosition, SnackbarDialogData } from '../../models';

@Component({
    selector: `floori-snackbar`,
    standalone: true,
    imports: [
        CommonModule,
        TranslationsModule,
        FlooriIconComponent,
        FlooriDialogModule,
        FlooriButtonComponent,
    ],
    templateUrl: './floori-snackbar.component.html',
    styleUrls: ['./floori-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriSnackbarComponent {
    private readonly data = inject<SnackbarDialogData>(FLOORI_DIALOG_DATA);

    get label(): string {
        return this.data.text;
    }

    get showCloseButton(): boolean {
        return !!this.data.showCloseButton;
    }

    @HostBinding('class.floori-snackbar') hostClass = true;

    @HostBinding('class.top')
    get isTopPosition(): boolean {
        return this.data.position === PopupPosition.top;
    }

    @HostBinding('class.bottom')
    get isBottomPosition(): boolean {
        return this.data.position === PopupPosition.bottom;
    }
}
