import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Element } from '@angular/compiler';
import { flooriConstants, HashMap, devLog } from '@floori-web-components';

@Injectable()
export class HtmlRendererService {
    private readonly renderer: Renderer2;

    get body(): HTMLElement {
        return this.doc.body;
    }

    constructor(
        private readonly rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private readonly doc: Document,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    addClass(elRef: HTMLElement, cls: string[]): void {
        if (!elRef || !cls?.length) {
            return;
        }

        cls.forEach(c => this.renderer.addClass(elRef, c));
    }

    addCssVars(cssVars: HashMap<string>, prefix: string = flooriConstants.cssVarPrefix): void {
        Object.keys(cssVars).forEach(key => {
            if (cssVars[key]) {
                const computeVarName = `--${prefix}${key}`;
                const cssVarValue = cssVars[key];
                this.doc.documentElement.style.setProperty(computeVarName, cssVarValue);
            }
        });
    }

    renderCSS(element: Element, style: Partial<CSSStyleDeclaration>): void {
        Object.keys(style).forEach(k => {
            const value = (style as Record<string, string>)[k];
            this.renderer.setStyle(element, k, value);
        });
    }

    removeCss(element: Element, key: keyof CSSStyleDeclaration): void {
        if (element) {
            this.renderer?.removeStyle(element, key as string);
        }
    }

    removeClass(elRef: HTMLElement, cls: string[]): void {
        if (!elRef || !cls?.length) {
            return;
        }

        cls.forEach(c => this.renderer.removeClass(elRef, c));
    }

    renderLinkTag(rel: LinkTagRel, href: string, type?: string, as?: string): Observable<void> {
        return new Observable<void>(obs => {
            if (!href) {
                obs.next();
                obs.complete();
                return;
            }
            const linkTag = this.doc.createElement('link');
            linkTag.href = href;
            linkTag.rel = rel;
            if (type) {
                linkTag.type = type;
            }
            if (as) {
                linkTag.as = as;
            }

            if (rel === 'prefetch') {
                obs.next();
                obs.complete();
                return;
            }

            linkTag.onload = (): void => {
                obs.next();
                obs.complete();
            };
            linkTag.onerror = (err): void => {
                devLog('Link tag error', err);
                obs.next();
                obs.complete();
            };
            this.doc.head.appendChild(linkTag);
        });
    }
}

type LinkTagRel = 'stylesheet' | 'prefetch' | string;
