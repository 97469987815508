import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FLOORI_STORAGE_PROVIDER } from '@floori-web-components';
import { StorageService } from './storage/storage.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: FLOORI_STORAGE_PROVIDER,
            useClass: StorageService,
        },
    ],
})
export class StorageModule {}
