import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriRadioGroupComponent } from './floori-radio-group/floori-radio-group.component';
import { FlooriRadioComponent } from './floori-radio/floori-radio.component';

const EXPORTED = [FlooriRadioComponent, FlooriRadioGroupComponent];

@NgModule({
    imports: [CommonModule],
    declarations: [...EXPORTED],
    exports: [...EXPORTED],
})
export class FlooriRadioModule {}
