import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[flooriDisableEnter]', standalone: true })
export class DisableEnterDirective {
    @Input('flooriDisableEnter') disable = false;

    @HostListener('keydown.enter', ['$event'])
    onEnterKeydown(event: Event): void {
        if (!this.disable) {
            return;
        }

        event.preventDefault();
        event.stopImmediatePropagation();
    }
}
