import { ChangeDetectorRef, Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[flooriSkeletonLoader]',
    standalone: true,
})
export class FlooriSkeletonLoaderDirective {
    private _loading = false;

    @Input('flooriSkeletonLoader') set loading(val: boolean) {
        if (this._loading !== val) {
            this._loading = val;
            this.cdr.markForCheck();
        }
    }

    @HostBinding('class.floori-skeleton-loader') get addSkeletonClass(): boolean {
        return this._loading;
    }

    constructor(private readonly cdr: ChangeDetectorRef) {}
}
