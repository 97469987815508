export interface ScreenBreakpoints {
    readonly tablet: string;
    readonly tabletRaw: number;
    readonly desktop: string;
    readonly desktopRaw: number;
}

const tabletBreakpoint = 768;
const desktopBreakpoint = 1024;

export const screenBreakpoints: ScreenBreakpoints = {
    tablet: `${tabletBreakpoint}px`,
    tabletRaw: tabletBreakpoint,
    desktop: `${desktopBreakpoint}px`,
    desktopRaw: desktopBreakpoint,
} as const;
