<div class="slider-container">
    <div class="slider-wrapper" [class.reseting]="showReset">
        <ngx-slider [options]="options" [(value)]="value"></ngx-slider>
    </div>
    <ng-container *ngIf="showReset">
        <button floori-button class="outline reverse small" (click)="reset()">
            <floori-icon name="fl_trash_o"></floori-icon>
            reset
        </button>
        <button floori-icon-button class="outline small" (click)="reset()">
            <floori-icon name="fl_trash_o"></floori-icon>
        </button>
    </ng-container>
</div>
