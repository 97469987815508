import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

let uniqueId = 0;

export interface RadioCheckChange<T = unknown> {
    value: T;
    id: string;
}

@Component({
    selector: 'floori-radio',
    templateUrl: './floori-radio.component.html',
    styleUrls: ['./floori-radio.component.scss'],
    standalone: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriRadioComponent<T = unknown> {
    @Input() value?: T;
    @Output() checkedChange = new EventEmitter<RadioCheckChange>();
    private readonly _uniqueId: string;
    private readonly idPrefix = 'floori-radio';
    private _checked = false;
    private _disabled = false;
    private _id?: string;
    private _parentId?: string;

    @Input() get checked(): boolean {
        return this._checked;
    }

    set checked(val: boolean) {
        const checked = coerceBooleanProperty(val);
        if (checked !== this.checked) {
            const shouldEmit = checked !== this.checked && checked;
            this._checked = checked;
            if (shouldEmit) {
                this.checkedChange.emit({
                    id: this.id,
                    value: this.value,
                });
            }
            this.cdr.markForCheck();
        }
    }

    @Input() get disabled(): boolean {
        return this._disabled;
    }

    set disabled(val: boolean) {
        const disabled = coerceBooleanProperty(val);
        if (this._disabled !== disabled) {
            this._disabled = disabled;
            if (this._disabled) {
                this.blur();
            }
            this.cdr.markForCheck();
        }
    }

    @Input()
    get id(): string {
        return this._id || this._uniqueId;
    }

    @Input()
    set parentId(val: string) {
        if (val && val !== this._parentId) {
            this._parentId = val;
            this._id = `${this._parentId}-${this.idPrefix}-${++uniqueId}`;
            this.cdr.markForCheck();
        }
    }

    @HostBinding('class.floori-radio') hostClass = true;

    constructor(private readonly cdr: ChangeDetectorRef, private readonly elRef: ElementRef) {
        this._uniqueId = `${this.idPrefix}-${++uniqueId}`;
    }

    blur(): void {
        this.elRef?.nativeElement?.blur();
    }

    focus(): void {
        this.elRef?.nativeElement?.focus();
    }
}
