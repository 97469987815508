import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { flooriConstants } from '../../constants';
import { AssetImageDto, ProductRGBAColor } from '../../models';

type FlooriPictureAssets =
    | { image?: AssetImageDto | null; rgbaColor?: ProductRGBAColor | null }
    | undefined;

@Component({
    // Cannot change selector because this component is used like directive
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'picture[floori-picture]',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './floori-picture.component.html',
    styleUrls: ['./floori-picture.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriPictureComponent {
    private readonly cdr = inject(ChangeDetectorRef);
    private _imgSet?: AssetImageDto;
    private defaultImg = '';
    private _showPlaceholder = false;
    private bgColor?: string;
    readonly sourceTablet = `media="(min-width:${flooriConstants.screenBreakpoints.tablet})"`;
    readonly sourceDesktop = `media="(min-width:${flooriConstants.screenBreakpoints.desktop})"`;

    @Input() alt = '';

    @Input() ignoreRGBABorderRadius = false;

    @Input() set imgSet(product: FlooriPictureAssets) {
        this._imgSet = undefined;
        this.defaultImg = '';
        this.bgColor = undefined;

        if (!product || !Object.values(product).length) {
            this._imgSet = undefined;
            return;
        } else if (product.image && Object.values(product.image).length) {
            this._imgSet = { ...product.image };
            this.defaultImg =
                this._imgSet?.small || this._imgSet?.medium || this._imgSet?.large || '';
        } else if (product.rgbaColor && Object.values(product.rgbaColor).length) {
            const { r, g, b, a } = product.rgbaColor;
            this.bgColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        }

        this.cdr.markForCheck();
    }

    get showPlaceholder(): boolean {
        return this._showPlaceholder;
    }

    get hasImage(): boolean {
        return !!this.defaultImg;
    }

    get mobile(): string {
        return this._imgSet?.small || this.defaultImg;
    }

    get tablet(): string {
        return this._imgSet?.medium || this.defaultImg;
    }

    get desktop(): string {
        return this._imgSet?.large || this.defaultImg;
    }

    get rgbaStyles(): Record<string, string> {
        const styles: Record<string, string> = { backgroundColor: this.bgColor || '' };

        if (this.ignoreRGBABorderRadius) {
            styles['borderRadius'] = '0';
        }

        return styles;
    }

    onImgError(): void {
        this._showPlaceholder = true;
        this.cdr.markForCheck();
    }
}
