<div class="floori-checkbox-mark" [class.checked]="checked" [class.disabled]="disabled">
    <input
        type="checkbox"
        [id]="inputId"
        (focus)="focusChanged(true)"
        (blur)="focusChanged(false)"
        [disabled]="disabled"
        [readonly]="readonly"
        [required]="required"
        [checked]="checked"
        (change)="checked = $any($event.target)?.checked" />
    <span class="floori-checkbox-checked"></span>
</div>
<label [for]="id" class="floori-checkbox-label">
    <p>
        <ng-content></ng-content>
        <span *ngIf="required" class="required-mark"> *</span>
    </p>
</label>
