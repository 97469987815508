import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable, take, tap } from 'rxjs';
import { BaseFacade } from '@floori-web/utils/abstract';
import { LocalStorageKeys, StorageProvider } from '@floori-web/models';
import { IntegrationType } from '@floori/models';
import { FLOORI_STORAGE_PROVIDER } from '@floori-web-components';
import { selectSplash, selectIntegrationType } from '../selectors';
import { cookiesActions } from '../actions/cookies.actions';
import {
    changeSplashVisibility,
    errorActions,
    visitorActions,
    integrationActions,
} from '../actions';

@Injectable({ providedIn: 'root' })
export class AppFacade extends BaseFacade {
    private readonly storage = inject<StorageProvider>(FLOORI_STORAGE_PROVIDER);
    readonly splash$ = this.store.select(selectSplash).pipe(distinctUntilChanged());

    get visitorId(): Observable<string> {
        return this.storage
            .get<string>(LocalStorageKeys.visitorId)
            .pipe(map(result => result || ''));
    }

    get integrationType(): Observable<IntegrationType> {
        return this.store.select(selectIntegrationType).pipe(map(state => state.integrationType));
    }

    constructor() {
        super();
        this.checkCookies();
    }

    setSplashScreen(visible: boolean, logoUrl?: string): void {
        return this.store.dispatch(
            changeSplashVisibility({
                visible,
                logoUrl,
            }),
        );
    }

    updateVisitorId(visitorId: string): void {
        this.store.dispatch(visitorActions.setVisitorId({ visitorId }));
    }

    setIntegrationType(integrationType: IntegrationType): void {
        this.store.dispatch(integrationActions.setIntegrationType({ integrationType }));
    }

    getIntegrationType(): IntegrationType {
        let integrationType: IntegrationType = IntegrationType.floori;

        this.store
            .select(selectIntegrationType)
            .pipe(
                take(1),
                tap(state => (integrationType = state.integrationType)),
            )
            .subscribe();

        return integrationType;
    }

    setError(show: boolean, message?: string): void {
        this.store.dispatch(errorActions.setError({ show, message }));
    }

    checkStorageAvailability(): void {
        try {
            const local = window.localStorage;
            const session = window.sessionStorage;
        } catch (e) {
            this.setError(true, 'storageAccessDenied');
        }
    }

    openCookiesDialog(): void {
        this.store.dispatch(cookiesActions.openDialog({ show: true }));
    }

    checkCookies(): void {
        this.storage
            .get<boolean>(LocalStorageKeys.cookies)
            .pipe(
                take(1),
                map(cookies => {
                    if (cookies === true || cookies === false) {
                        this.store.dispatch(cookiesActions.handleSavedConsent({ agreed: cookies }));
                        return;
                    }

                    this.openCookiesDialog();
                }),
            )
            .subscribe();
    }
}
