import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    inject,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationsModule } from '../../translations';
import { FieldTranslation, PopupDirection } from '../../models';

@Component({
    selector: 'floori-tooltip',
    standalone: true,
    imports: [CommonModule, TranslationsModule],
    templateUrl: './floori-tooltip.component.html',
    styleUrls: ['./floori-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriTooltipComponent {
    readonly cdr = inject(ChangeDetectorRef);
    text: string | FieldTranslation = '';
    width?: number;
    direction?: PopupDirection;
    disableArrow?: boolean;
    disableTranslation?: boolean;

    @HostBinding('class.floori-tooltip') hostClass = true;

    @HostBinding('class.disable-arrow') get disableArrowClass(): boolean {
        return !!this.disableArrow;
    }

    @HostBinding('class') get setDirection(): string {
        switch (this.direction) {
            case PopupDirection.above:
                return 'above';
            case PopupDirection.below:
                return 'below';
            case PopupDirection.right:
                return 'right';
            case PopupDirection.left:
                return 'left';
            default:
                return 'none';
        }
    }

    @HostBinding('style.width') get setWidth(): string | null {
        return this.width ? `${this.width}px` : null;
    }
}
