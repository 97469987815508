<div class="floori-radio-mark" [class.checked]="checked">
    <input
        type="radio"
        [id]="id"
        [disabled]="disabled"
        [checked]="checked"
        (change)="checked = $any($event.target)?.checked" />
    <div class="floori-radio-checked"></div>
</div>
<label [for]="id" class="floori-radio-label floori-p">
    <ng-content></ng-content>
</label>
