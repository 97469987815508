import { createAction, props } from '@ngrx/store';
import { CompanyConfig } from '@floori/models';
import { Languages } from '@floori-web-components';

export const initConfig = createAction(
    '[Config] Init config',
    props<{ companyConfig: CompanyConfig; storedLang: Languages | null }>(),
);

export const changeLang = createAction('[Config] Change lang', props<{ lang: Languages }>());
