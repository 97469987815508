import { catchError, forkJoin, Observable, of, switchMap, take } from 'rxjs';
import { CompanyConfig } from '@floori/models';
import {
    ConfigProvider,
    DraftModeProvider,
    FLOORI_CONFIG_PROVIDER,
    FLOORI_DRAFT_MODE_PROVIDER,
    FLOORI_NAVIGATION_EVENTS,
    NavigationEventsProvider,
} from '@floori-web/models';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { COMPANY_CONFIG_RAW, FLOORI_TRACKING_PROVIDER } from '@floori-web/constants';
import { Router } from '@angular/router';
import { FlooriTrackingProvider } from '@floori-web/tracking';
import {
    FLOORI_TRANSLATOR,
    Languages,
    TranslationsLoaderService,
    TranslationsProvider,
} from '@floori-web-components';

export const appInitializer =
    (
        config: CompanyConfig,
        configProvider: ConfigProvider,
        translateProvider: TranslationsProvider,
        navigationEvents: NavigationEventsProvider,
        trackingProvider: FlooriTrackingProvider,
        draftModeProvider: DraftModeProvider,
        translationsLoader: TranslationsLoaderService,
    ) =>
    (): Observable<unknown> => {
        configProvider.initConfig(config);
        translationsLoader.init({
            translationScope: config?.dynamicFeatures?.translationScope,
        });
        navigationEvents.init();
        draftModeProvider.init();
        return configProvider.companyConfig$.pipe(
            take(1),
            switchMap(companyConfig =>
                forkJoin([
                    translateProvider.init({
                        languages: companyConfig.company?.languages || [],
                        currentLang: companyConfig.currentLang || Languages.en,
                    }),
                    trackingProvider.init().pipe(catchError(() => of(void 0))),
                ]),
            ),
        );
    };

export const appInitializerProvider: Provider = {
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    multi: true,
    deps: [
        COMPANY_CONFIG_RAW,
        FLOORI_CONFIG_PROVIDER,
        FLOORI_TRANSLATOR,
        FLOORI_NAVIGATION_EVENTS,
        FLOORI_TRACKING_PROVIDER,
        FLOORI_DRAFT_MODE_PROVIDER,
        TranslationsLoaderService,
        Router,
    ],
};
