import { FieldTranslation } from '@floori-web-components';
import { ActionButton } from './action-button';

export enum CustomButtonTypes {
    addToCart = 'addToCart',
    calculator = 'calculator',
    custom = 'custom',
    quote = 'quote',
    devtools = 'devtools',
    sample = 'sample',
    welcomeScreen = 'welcomeScreen',
}

export enum QuoteType {
    standard = 'standard',
    iframe = 'iframe',
}

export class CtaButtonDto implements ActionButton {
    constructor(
        public readonly id: string,
        public readonly visibility: boolean,
        public readonly icon?: string,
        public readonly text?: FieldTranslation,
        public readonly disclaimer?: FieldTranslation,
        public readonly disclaimerRequired?: boolean,
        public readonly email?: string,
        public readonly url?: string,
    ) {}
}

export class QuoteButtonDto extends CtaButtonDto {
    public readonly quoteType?: QuoteType;
    public readonly iframeUrl?: string;
    public readonly isPostalCodeRequired?: boolean;
}

export class CalculatorButtonDto extends CtaButtonDto {
    public readonly installationPrice?: string;
    public readonly isRequestQuoteButtonVisible?: boolean;
}

export type CtaConfigDto = {
    [key in CustomButtonTypes]: CtaButtonDto;
};
