import { Languages } from '@floori-web-components';
import { Language } from '@floori/models';

export const flooriLanguages: Record<Languages, Language> = {
    [Languages.ara]: {
        language: 'langArab',
        code: Languages.ara,
        flag: 'assets/flags/ara.svg',
    },
    [Languages.bg]: {
        language: 'langBulgarian',
        code: Languages.bg,
        flag: 'assets/flags/bg.svg',
    },
    [Languages.bl]: {
        language: 'langBelgian',
        code: Languages.bl,
        flag: 'assets/flags/bl.svg',
    },
    [Languages.ch]: {
        language: 'langChinese',
        code: Languages.ch,
        flag: 'assets/flags/ch.svg',
    },
    [Languages.cs]: {
        language: 'langCzech',
        code: Languages.cs,
        flag: 'assets/flags/cs.svg',
    },
    [Languages.da]: {
        language: 'langDanish',
        code: Languages.da,
        flag: 'assets/flags/da.svg',
    },
    [Languages.de]: {
        language: 'langGerman',
        code: Languages.de,
        contactCode: '/de',
        flag: 'assets/flags/de.svg',
    },
    [Languages.en]: {
        language: 'langEnglish',
        code: Languages.en,
        contactCode: '/en',
        flag: 'assets/flags/en.svg',
    },
    [Languages.us]: {
        language: 'langEnglishUS',
        code: Languages.us,
        flag: 'assets/flags/us.svg',
    },
    [Languages.au]: {
        language: 'langEnglishAU',
        code: Languages.au,
        flag: 'assets/flags/au.svg',
    },
    [Languages.es]: {
        language: 'langSpain',
        code: Languages.es,
        contactCode: '/es',
        flag: 'assets/flags/es.svg',
    },
    [Languages.fi]: {
        language: 'langFinnish',
        code: Languages.fi,
        flag: 'assets/flags/fi.svg',
    },
    [Languages.fr]: {
        language: 'langFrench',
        code: Languages.fr,
        contactCode: '/fr',
        flag: 'assets/flags/fr.svg',
    },
    [Languages.gr]: {
        language: 'langGreek',
        code: Languages.gr,
        flag: 'assets/flags/gr.svg',
    },
    [Languages.hr]: {
        language: 'langCroatian',
        code: Languages.hr,
        flag: 'assets/flags/hr.svg',
    },
    [Languages.hu]: {
        language: 'langHungarian',
        code: Languages.hu,
        flag: 'assets/flags/hu.svg',
    },
    [Languages.id]: {
        language: 'langIndonesian',
        code: Languages.id,
        flag: 'assets/flags/id.svg',
    },
    [Languages.il]: {
        language: 'langIsrael',
        code: Languages.il,
        flag: 'assets/flags/il.svg',
    },
    [Languages.it]: {
        language: 'langItalian',
        code: Languages.it,
        contactCode: '/it',
        flag: 'assets/flags/it.svg',
    },
    [Languages.ja]: {
        language: 'langJapanese',
        code: Languages.ja,
        flag: 'assets/flags/ja.svg',
    },
    [Languages.kr]: {
        language: 'langKorean',
        code: Languages.kr,
        flag: 'assets/flags/kr.svg',
    },
    [Languages.lt]: {
        language: 'langLithuanian',
        code: Languages.lt,
        flag: 'assets/flags/lt.svg',
    },
    [Languages.nl]: {
        language: 'langDutch',
        code: Languages.nl,
        flag: 'assets/flags/nl.svg',
    },
    [Languages.no]: {
        language: 'langNorwegian',
        code: Languages.no,
        flag: 'assets/flags/no.svg',
    },
    [Languages.pl]: {
        language: 'langPolish',
        code: Languages.pl,
        contactCode: '/pl',
        flag: 'assets/flags/pl.svg',
    },
    [Languages.ptbr]: {
        language: 'langBrazilianPortuguese',
        code: Languages.ptbr,
        flag: 'assets/flags/ptbr.svg',
    },
    [Languages.pt]: {
        language: 'langPortuguese',
        code: Languages.pt,
        contactCode: '/pt-pt',
        flag: 'assets/flags/pt.svg',
    },
    [Languages.ro]: {
        language: 'langRomania',
        code: Languages.ro,
        flag: 'assets/flags/ro.svg',
    },
    [Languages.ru]: {
        language: 'langRussian',
        code: Languages.ru,
        flag: 'assets/flags/ru.svg',
    },
    [Languages.sa]: {
        language: 'langSaudiArabian',
        code: Languages.sa,
        flag: 'assets/flags/sa.svg',
    },
    [Languages.se]: {
        language: 'langSwedish',
        code: Languages.se,
        flag: 'assets/flags/se.svg',
    },
    [Languages.sk]: {
        language: 'langSlovak',
        code: Languages.sk,
        flag: 'assets/flags/sk.svg',
    },
    [Languages.sl]: {
        language: 'langSlovenian',
        code: Languages.sl,
        flag: 'assets/flags/sl.svg',
    },
    [Languages.sr]: {
        language: 'langSerbian',
        code: Languages.sr,
        flag: 'assets/flags/sr.svg',
    },
    [Languages.tr]: {
        language: 'langTurkish',
        code: Languages.tr,
        contactCode: '/tr',
        flag: 'assets/flags/tr.svg',
    },
    [Languages.th]: {
        language: 'langThai',
        code: Languages.th,
        flag: 'assets/flags/th.svg',
    },
    [Languages.uk]: {
        language: 'langUkrainian',
        code: Languages.uk,
        flag: 'assets/flags/uk.svg',
    },
    [Languages.vie]: {
        language: 'langVietnamese',
        code: Languages.vie,
        flag: 'assets/flags/vie.svg',
    },
} as const;
