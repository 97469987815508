import {
    CompanyType,
    ConfigState,
    FlooriFontFamily,
    FlooriPages,
    FlooriThemes,
    Layouts,
} from '@floori/models';
import { createReducer, on } from '@ngrx/store';
import { flooriConstants, FlooriIcons, isoToFlooriLangs, Languages } from '@floori-web-components';
import * as ConfigActions from './config.actions';

export const CONFIG_FEATURE_KEY = 'flooriConfig';

export const initialState: ConfigState = {
    // TODO: This should be a seperate factory when we will have more layouts
    layouts: {
        [FlooriPages.ar]: Layouts.baseAr,
        [FlooriPages.rooms]: Layouts.baseRooms,
        [FlooriPages.room]: Layouts.baseRoom,
        [FlooriPages.notFound]: Layouts.notFound,
        [FlooriPages.calculator]: null,
    },
};

const initConfig = on(
    ConfigActions.initConfig,
    (state: ConfigState, { companyConfig, storedLang }): ConfigState => {
        // URL param name for language
        const paramLangKey = 'lang';
        // Reading params from URL
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        // Reading language from URL params
        const paramLang = queryParams.get(paramLangKey);
        // Extended and short versions of ISO language codes where
        // short version is ISO 639-1 standard
        // and extended is short version with country code
        // to differ same language in different countries
        const paramLangExtended = isoToFlooriLangs[paramLang || ''] as Languages; // For example en-US, de-DE
        const paramLangShort = isoToFlooriLangs[paramLang?.split('-')?.[0] || ''] as Languages; // For example en, de
        // Reading browser settings language also using short and extended ISO version
        const browserLangExtended = isoToFlooriLangs[window?.navigator?.language] as Languages;
        const browserLangShort = isoToFlooriLangs[
            window?.navigator?.language?.split('-')?.[0]
        ] as Languages;
        if (paramLang) {
            // Deleting param from url if it exists
            const url = window.location.href;
            const urlObject = new URL(url);
            urlObject.searchParams.delete(paramLangKey);
            window.history.replaceState({}, '', urlObject.toString());
        }
        // Language priority. Will use first defined value if it exists in portal languages selection
        let currentLang = [
            paramLangExtended,
            paramLangShort,
            storedLang,
            companyConfig?.defaultLanguage,
            browserLangExtended,
            browserLangShort,
            Languages.en,
        ].find(lang => !!lang && companyConfig?.languages?.includes(lang));
        // If none of above is valid then using first language from languages list from portal settings
        // If no languages are selected in portal loading english as default and only language
        if (!currentLang) {
            currentLang = companyConfig?.languages?.[0] || Languages.en;
        }
        return {
            ...state,
            company: companyConfig
                ? {
                      ...companyConfig,
                      epoxyView: companyConfig?.companyType === CompanyType.epoxy,
                  }
                : undefined,
            // Bellow values should be set on floori panel in the end
            theme: {
                theme: companyConfig?.themeSettings?.theme || FlooriThemes.default,
                fontFamily: companyConfig?.themeSettings?.fontFamily || FlooriFontFamily.inter,
                iconFamily: companyConfig?.themeSettings?.iconFamily || FlooriIcons.hero,
            },
            logoUrl: companyConfig?.logo || flooriConstants.flooriLogo,
            logoSplash: companyConfig?.logo || flooriConstants.flooriLogoVertical,
            langs: companyConfig?.languages || [currentLang],
            currentLang,
            themeCssVars: companyConfig?.themeCssVars || {},
            epoxyView: companyConfig?.companyType === CompanyType.epoxy,
        };
    },
);

const changeLang = on(
    ConfigActions.changeLang,
    (state: ConfigState, { lang }): ConfigState => ({
        ...state,
        currentLang: lang,
    }),
);

export const configReducer = createReducer(initialState, initConfig, changeLang);
