import { Observable } from 'rxjs';
import * as mixpanel from 'mixpanel-browser';
import { Mixpanel } from 'mixpanel-browser';
import { isDevMode, Provider } from '@angular/core';
import { HashMap } from '@floori-web-components';
import { FLOORI_INTERACTION_PROVIDER, FlooriInteractionProvider } from '../providers';

export class MixpanelService implements FlooriInteractionProvider {
    private mixpanelBase?: Mixpanel;

    init(key: string): Observable<void> {
        return new Observable<void>(obs => {
            this.mixpanelBase = mixpanel.init(key, { debug: isDevMode() }, 'floori');
            obs.next();
            obs.complete();
        });
    }

    enable(): void {
        this.mixpanelBase?.opt_in_tracking();
    }

    disable(): void {
        this.mixpanelBase?.opt_out_tracking();
    }

    isEnabled(): boolean {
        return this.mixpanelBase?.has_opted_in_tracking() === true;
    }

    track(eventName: string, props: HashMap): void {
        if (this.mixpanelBase && this.isEnabled()) {
            this.mixpanelBase.track(eventName, props);
        }
    }
}

export const mixpanelProvider: Provider = {
    provide: FLOORI_INTERACTION_PROVIDER,
    useClass: MixpanelService,
};
