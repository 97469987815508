import { AssetImageDto } from '@floori-web-components';
import { ProductPlacementOptions } from '../product';

export interface RoomCategoryListDto {
    [key: string]: RoomDto;
}

export interface RoomDto {
    readonly id?: string;
    readonly name?: string;
    readonly category?: string;
    readonly image?: AssetImageDto;
    readonly image4k?: string;
    readonly placementOptions?: ProductPlacementOptions[];
    readonly visitorRoom?: boolean;
}

export interface RoomCreateDto {
    readonly roomId: string;
    readonly visitorId: string;
    readonly room: RoomDto & { thumbnail: string };
}

export enum RoomPhotoOrientation {
    horizontal = 'horizontal',
    vertical = 'vertical',
}
