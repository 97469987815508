import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    inject,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'floori-progress-loader',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './floori-progress-loader.component.html',
    styleUrls: ['./floori-progress-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriProgressLoaderComponent {
    private readonly cdr = inject(ChangeDetectorRef);
    private _mainLabel = '';

    @Input() set mainLabel(val: string | number) {
        this._mainLabel = `${typeof val === 'number' ? val?.toString() : val}%`;
        this.cdr.markForCheck();
    }

    get mainLabel(): string {
        return this._mainLabel;
    }

    @HostBinding('class.floori-progress-loader') hostClass = true;
}
