import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    forwardRef,
    HostBinding,
    Input,
    QueryList,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FlooriRadioComponent } from '../floori-radio/floori-radio.component';
import { FlooriControl } from '../../../utils';
import { HashMap } from '../../../models';

let uniqueId = 0;

@Component({
    // Cannot change selector because this component is used like directive
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[floori-radio-group]',
    templateUrl: './floori-radio-group.component.html',
    styleUrls: ['./floori-radio-group.component.scss'],
    standalone: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FlooriRadioGroupComponent,
        },
    ],
})
export class FlooriRadioGroupComponent<T = unknown>
    extends FlooriControl
    implements ControlValueAccessor
{
    @ContentChildren(forwardRef(() => FlooriRadioComponent), { descendants: true })
    radios?: QueryList<FlooriRadioComponent>;
    @Input() id: string;

    private readonly _uniqueId: string;
    private readonly idPrefix = 'floori-radio-group';
    private _value?: T;
    private _row = false;
    private radiosMap: HashMap<FlooriRadioComponent> = {};

    @Input()
    override get disabled(): boolean {
        return super.disabled;
    }

    override set disabled(val: boolean) {
        super.disabled = val;
        this.setDisabled(this.disabled);
    }

    @Input() get value(): T {
        // Type T so cannot set other value in case of undefined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._value!;
    }

    set value(val: T) {
        if (val !== this._value) {
            this._value = val;
            this.onTouch(this._value);
            this.onChange(this._value);
            this.cdr.markForCheck();
        }
    }

    @Input() get row(): boolean {
        return this._row;
    }

    set row(val: boolean) {
        const row = coerceBooleanProperty(val);
        if (this._row !== row) {
            this._row = row;
            this.cdr.markForCheck();
        }
    }

    get inputId(): string {
        return this.id || this._uniqueId;
    }

    @HostBinding('class.floori-radio-group') hostClass = true;

    @HostBinding('[class.row]') hostRow = 'row';

    @HostBinding('[class.disabled]') hostDisabled = 'disabled';

    @HostBinding('[id]') hostId = 'inputId';

    constructor() {
        super();
        this.id = this._uniqueId = `${this.idPrefix}-${++uniqueId}`;
    }

    writeValue(value: T): void {
        if (this.onChangeInit) {
            this._value = value;
            this.setupRadios();
        }
    }

    private setupRadios(): void {
        this.radiosMap = {};
        this.radios?.forEach(radio => {
            radio.parentId = this._uniqueId;
            radio.checked = this.value === radio.value;
            radio.checkedChange.pipe(takeUntil(this.destroyed)).subscribe(data => {
                this.value = data?.value as T;
                this.changeRadioValues();
            });
            this.radiosMap[radio.id] = radio;
        });
    }

    private changeRadioValues(): void {
        this.radios?.forEach(radio => {
            const checked = this.value === radio.value;
            radio.checked = checked;

            if (checked) {
                radio.focus();
            } else {
                radio.blur();
            }
        });
    }

    private setDisabled(disabled: boolean): void {
        this.radios?.forEach(radio => {
            radio.disabled = disabled;
        });
    }
}
