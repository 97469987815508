import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { ReactiveFormsModule } from '@angular/forms';
import { FlooriIconComponent } from '../floori-icon';
import { FlooriButtonComponent } from '../floori-button';
import { Orientation } from '../../models';

@Component({
    selector: 'floori-slider',
    standalone: true,
    imports: [
        CommonModule,
        FlooriButtonComponent,
        NgxSliderModule,
        ReactiveFormsModule,
        FlooriIconComponent,
    ],
    templateUrl: './floori-slider.component.html',
    styleUrls: ['./floori-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriSliderComponent implements OnInit {
    private readonly cdr = inject(ChangeDetectorRef);
    private _max = 1;
    private _min = 0;
    private _value?: number = undefined;
    private _initialValue: number = this._value || 0;

    @Input() showReset = true;
    @Input() disabled = false;
    @Input() withLabel = true;
    @Input() slideAppendix = '';
    @Input() orientation = Orientation.portrait;
    @Output() valueChange = new EventEmitter<number>();

    @Input() set value(val: number) {
        if (val !== this._value) {
            if (this._value !== undefined) {
                this.valueChange.emit(val);
            }
            this._value = val;
        }
        this.cdr.markForCheck();
    }

    get value(): number {
        return this._value !== null && this._value !== undefined ? this._value : this._min;
    }

    @Input() set min(val: number) {
        if (this._min !== val) {
            this._min = val;
            if (this._initialValue < this._min) {
                this._initialValue = this._min;
            }
        }
        this.cdr.markForCheck();
    }

    get min(): number {
        return this._min;
    }

    @Input() set max(val: number) {
        if (this._max !== val) {
            this._max = val;
        }
        this.cdr.markForCheck();
    }

    get max(): number {
        return this._max;
    }

    @HostBinding('class.floori-slider') hostClass = true;

    @HostBinding('class')
    get hostCls(): string {
        if (!this.orientation) {
            return '';
        }

        return this.orientation === Orientation.landscape ? 'landscape' : 'portrait';
    }

    get options(): Options {
        return {
            floor: this._min,
            ceil: this._max,
            vertical: this.orientation === Orientation.portrait,
            rightToLeft: false,
            disabled: this.disabled,
            hideLimitLabels: true,
            showSelectionBar: true,
            hidePointerLabels: !this.withLabel,
            translate: (value: number): string => `${value}${this.slideAppendix}`,
        };
    }

    ngOnInit(): void {
        if (this._value === null || this._value === undefined) {
            this._value = this._min;
        }
        this._initialValue = this._value;
        this.cdr.markForCheck();
    }

    reset(): void {
        this.value = this._initialValue || 0;
    }
}
