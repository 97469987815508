import { Pipe, PipeTransform } from '@angular/core';
import { assetImageMapper } from '@floori-web/utils/pipes';
import { AssetImageDto } from '@floori-web-components';

@Pipe({
    name: 'assetImage',
    standalone: true,
    pure: true,
})
export class AssetImagePipe implements PipeTransform {
    transform(image?: AssetImageDto): AssetImageDto {
        return assetImageMapper(image);
    }
}
