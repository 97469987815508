import { AssetImageDto, ProductRGBAColor } from '@floori-web-components';

export enum FilterResultType {
    catalog = 'catalog',
    product = 'product',
}

export abstract class FilterResultBase {
    type?: FilterResultType;

    protected constructor(
        public readonly id: string,
        public image?: AssetImageDto | null,
        public rgbaColor?: ProductRGBAColor | null,
    ) {}
}
