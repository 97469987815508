import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { devLog } from '../../tools';
import { TranslationsLoaderConfig } from '../models/translations-loader-config';

@Injectable()
export class TranslationsLoaderService implements TranslocoLoader {
    private readonly http = inject(HttpClient);
    private readonly localUrl = `./assets/translations/`;
    private translationScope?: string;

    init(config: TranslationsLoaderConfig): void {
        this.translationScope = config.translationScope;
    }

    getTranslation(lang: string): Observable<Translation> {
        const url = `${this.localUrl}${lang}.json`;
        const scope = this.translationScope || '';
        const scopeUrl = `${this.localUrl}${scope}/${lang}.json`;

        return forkJoin([
            this.http.get<Translation>(url),
            scope ? this.http.get<Translation>(scopeUrl) : of(null),
        ]).pipe(
            catchError(() => {
                devLog('Error fetching lang');
                return of([{}, null]);
            }),
            map(([translation, scopeTranslation]) => {
                let result = { ...translation };
                if (scopeTranslation) {
                    result = { ...result, ...scopeTranslation };
                }
                return result;
            }),
        );
    }
}
