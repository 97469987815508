import { CqrsEvent } from '@floori-web/cqrs';
import { ElementRef } from '@angular/core';
import { FlooriDialogProvider, FlooriDialogRef, flooriHelperClasses } from '@floori-web-components';
import { ComponentType } from '@angular/cdk/overlay';

abstract class ProductListEvent<T, D = unknown> extends CqrsEvent {
    protected constructor(
        protected dialogProvider: FlooriDialogProvider,
        protected componentRef: ComponentType<T>,
    ) {
        super();
    }

    displayDialog(data?: D, positionRef?: ElementRef | HTMLElement): FlooriDialogRef<T> {
        return this.dialogProvider.open<T, D>(this.componentRef, {
            positionRef: positionRef as ElementRef,
            hasBackdrop: false,
            panelClass: flooriHelperClasses['containOverlay'],
            maxWidth: (positionRef as HTMLElement)?.offsetWidth || 'initial',
            providers: [],
            data,
        });
    }
}

export class SelectedProductsEvent<T, D = unknown> extends ProductListEvent<T, D> {
    select: boolean;
    constructor(
        protected override readonly dialogProvider: FlooriDialogProvider,
        protected override readonly componentRef: ComponentType<T>,
    ) {
        super(dialogProvider, componentRef);
        this.select = true;
    }
}

export class LikedProductsEvent<T, D = unknown> extends ProductListEvent<T, D> {
    readonly liked: boolean;
    constructor(
        protected override readonly dialogProvider: FlooriDialogProvider,
        protected override readonly componentRef: ComponentType<T>,
    ) {
        super(dialogProvider, componentRef);
        this.liked = true;
    }
}

export type RoomTopbarEvents<T, D = unknown> = LikedProductsEvent<T> | SelectedProductsEvent<T>;
