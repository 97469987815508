import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriIconComponent } from '../floori-icon';
import { FlooriProgressLoaderComponent } from '../floori-progress-loader';
import { FlooriLoaderType } from './floori-loader-type';

@Component({
    // Cannot change selector because this component is used like directive
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[floori-loader]',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, FlooriIconComponent, FlooriProgressLoaderComponent],
    templateUrl: './floori-loader.component.html',
    styleUrls: ['./floori-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriLoaderComponent {
    readonly flooriLoaderType = FlooriLoaderType;
    @Input() label = '';
    @Input() type = FlooriLoaderType.circle;
    @Input() showProgress = false;
    @Input() progressValue = 0;

    @HostBinding('class.floori-loader') hostClass = true;

    @HostBinding('class.labeled')
    get withlabel(): boolean {
        return !!this.label;
    }
}
