import { BaseEffects } from '@floori-web/utils/abstract';
import { createEffect, ofType } from '@ngrx/effects';
import { switchMap, takeUntil, tap, map } from 'rxjs';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { StorageProvider, LocalStorageKeys } from '@floori-web/models';
import {
    FlooriDialogProvider,
    FLOORI_DIALOG_SERVICE,
    FlooriDialogRef,
    flooriHelperClasses,
    FLOORI_STORAGE_PROVIDER,
} from '@floori-web-components';
import { FLOORI_TRACKING_PROVIDER } from '@floori-web/constants';
import { CookiesDialogComponent } from '@floori-web/ui/cookies-dialog';
import { FlooriTrackingProvider } from '@floori-web/tracking';
import { cookiesActions } from '../actions/cookies.actions';

@Injectable()
export class CookiesEffects extends BaseEffects implements OnDestroy {
    private readonly dialog = inject<FlooriDialogProvider>(FLOORI_DIALOG_SERVICE);
    private readonly storage = inject<StorageProvider>(FLOORI_STORAGE_PROVIDER);
    private readonly tracking = inject<FlooriTrackingProvider>(FLOORI_TRACKING_PROVIDER);
    private dialogRef?: FlooriDialogRef<CookiesDialogComponent>;

    private readonly handleSavedConsent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(cookiesActions.handleSavedConsent),
                tap(({ agreed }) => {
                    if (agreed) {
                        this.tracking.enableAllTracking();
                    } else {
                        this.tracking.disableAllTracking();
                    }
                }),
            ),
        { dispatch: false },
    );

    private readonly openDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(cookiesActions.openDialog),
                tap(({ show }) => {
                    if (this.dialogRef && show) {
                        return;
                    }

                    if (!show) {
                        this.dialogRef?.close();
                        return;
                    }

                    this.showDialog();
                }),
            ),
        { dispatch: false },
    );

    private showDialog(): void {
        this.dialogRef = this.dialog.open<CookiesDialogComponent>(CookiesDialogComponent, {
            backdropClass: flooriHelperClasses['darkOverlay'],
            panelClass: flooriHelperClasses['centerOverlay'],
            disableClose: true,
        }) as FlooriDialogRef<CookiesDialogComponent>;

        this.dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.destroyed$),
                map((response: unknown) => response as boolean),
                map(response => {
                    this.dialogRef = undefined;

                    if (response) {
                        this.tracking.enableAllTracking();
                    } else {
                        this.tracking.disableAllTracking();
                    }
                    return response;
                }),
                switchMap(response =>
                    this.storage.set<boolean>(LocalStorageKeys.cookies, response),
                ),
            )
            .subscribe();
    }
}
