import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyConfig, ConfigState } from '@floori/models';
import { ConfigProvider, LocalStorageKeys, StorageProvider } from '@floori-web/models';
import { distinctUntilChanged, map, tap } from 'rxjs';
import { FLOORI_STORAGE_PROVIDER, Languages } from '@floori-web-components';
import { changeLang, initConfig } from './config.actions';
import {
    selectCompanyConfig,
    selectCompanyLogo,
    selectConfigFeature,
    selectPagesLayouts,
} from './config.selectors';

@Injectable()
export class ConfigFacade implements ConfigProvider {
    private readonly storageProvider = inject<StorageProvider>(FLOORI_STORAGE_PROVIDER);
    readonly companyConfig$ = this.store.select(selectConfigFeature);
    readonly companyConfigRaw$ = this.store.select(selectCompanyConfig);
    readonly dynamicFeatures$ = this.companyConfig$.pipe(
        map(config => config?.company?.dynamicFeatures),
    );
    readonly pagesLayouts$ = this.store.select(selectPagesLayouts);
    readonly companyLogo$ = this.store
        .select(selectCompanyLogo)
        .pipe(distinctUntilChanged((p, c) => p !== c));

    constructor(private readonly store: Store<ConfigState>) {}

    initConfig(companyConfig: CompanyConfig): void {
        this.storageProvider
            .get(LocalStorageKeys.visitorLang)
            .pipe(
                tap(visitorLang => {
                    const storedLang = visitorLang as Languages | null;
                    this.store.dispatch(initConfig({ companyConfig, storedLang }));
                }),
            )
            .subscribe();
    }

    changeLang(code: Languages): void {
        if (!code) {
            return;
        }
        this.store.dispatch(changeLang({ lang: code }));
    }
}
