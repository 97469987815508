<ng-content select=".floori-input-prefix"></ng-content>
<textarea
    [flooriDisableEnter]="disableSubmit"
    *ngIf="type === 'textarea'"
    [placeholder]="placeholder"
    (focus)="focusChanged(true)"
    (blur)="focusChanged(false)"
    (input)="value = $any($event.target)?.value"
    [value]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"></textarea>

<input
    [flooriDisableEnter]="disableSubmit"
    *ngIf="type !== 'textarea'"
    [type]="type"
    [placeholder]="placeholder"
    (focus)="focusChanged(true)"
    (blur)="focusChanged(false)"
    (input)="value = $any($event.target)?.value"
    [value]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required" />
<div class="floori-input-clear" *ngIf="clear && !disabled && !readonly" (click)="clearValue()">
    <button *ngIf="value" floori-icon-button>
        <floori-icon name="fl_close"></floori-icon>
    </button>
</div>
<ng-content select=".floori-input-appendix"></ng-content>
