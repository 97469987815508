<svg
    class="floori-loader-circle"
    *ngIf="flooriLoaderType.circle === type"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style="
        margin-right: -2px;
        display: block;
        background-repeat-y: initial;
        background-repeat-x: initial;
        background-color: transparent;
        animation-play-state: running;
    ">
    <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        stroke-width="8"
        stroke-linecap="round"
        r="45"
        stroke-dasharray="164.93361431346415 56.97787143782138"
        transform="matrix(1,0,0,1,0,0)"
        style="transform: matrix(1, 0, 0, 1, 0, 0); animation-play-state: paused"></circle>
</svg>
<div class="floori-loader-floori" *ngIf="type === flooriLoaderType.floori">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>
<div class="floori-loader-stars" *ngIf="type === flooriLoaderType.stars">
    <floori-icon name="fl_star_s"></floori-icon>
    <floori-icon name="fl_star_s"></floori-icon>
    <floori-icon name="fl_star_s"></floori-icon>
</div>
<div class="floori-loader-progress-bar" *ngIf="showProgress">
    <floori-progress-loader
        class="loader-progress"
        [mainLabel]="progressValue"></floori-progress-loader>
</div>
<p class="floori-caption floori-semi-bold" *ngIf="label">
    {{ label }}
</p>
