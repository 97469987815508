import { Directive, HostListener, inject, Input } from '@angular/core';
import { FLOORI_TRACKING_PROVIDER } from '@floori-web/constants';
import { FlooriTrackingProvider, TrackingTypes } from '@floori-web/tracking';
import { HashMap } from '@floori-web-components';

@Directive({ selector: '[flooriTrackClick]', standalone: true })
export class TrackClickDirective {
    @Input('flooriTrackClick') eventName = '';
    @Input() props?: HashMap;
    @Input() trackingTypes: TrackingTypes[] = [];
    private trackingProvider = inject<FlooriTrackingProvider>(FLOORI_TRACKING_PROVIDER);

    @HostListener('click', [])
    clicked(): void {
        if (!this.eventName) {
            return;
        }
        this.trackingProvider.track(
            this.eventName,
            this.props || {},
            new Set<TrackingTypes>(this.trackingTypes),
        );
    }
}
