import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    NgZone,
    OnDestroy,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { OverlayRef } from '@angular/cdk/overlay';
import { FlooriDialogConfig } from '../../common';
import { FlooriWindow } from '../../../../models';
import { FLOORI_WINDOW } from '../../../../constants';

@Component({
    selector: 'floori-dialog-container',
    templateUrl: './floori-dialog-container.component.html',
    styleUrls: ['./floori-dialog-container.component.scss'],
    standalone: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FlooriDialogContainerComponent
    extends CdkDialogContainer<FlooriDialogConfig>
    implements OnDestroy
{
    private _maxHeight = 0;

    @HostBinding('attr.tabindex') tabindex = '-1';

    @HostBinding('[id]') id = '_config.id';

    @HostBinding('class') get lasses(): string {
        const hostClasses = 'floori-dialog-container floori-dialog';
        const customClasses = Array.isArray(this.dialogConfig?.containerClass)
            ? this.dialogConfig?.containerClass.join(' ')
            : this.dialogConfig?.containerClass;
        return [hostClasses, customClasses].filter(className => className).join(' ');
    }

    @HostBinding('style.maxHeight') get maxHeight(): string {
        if (this._maxHeight < 1) {
            return '';
        }

        return `${this._maxHeight}px`;
    }

    constructor(
        elementRef: ElementRef,
        focusTrapFactory: FocusTrapFactory,
        checker: InteractivityChecker,
        ngZone: NgZone,
        overlayRef: OverlayRef,
        private dialogConfig: FlooriDialogConfig,
        @Inject(FLOORI_WINDOW) private windowRef: FlooriWindow,
        @Optional() @Inject(DOCUMENT) document: Document,
        focusMonitor?: FocusMonitor,
    ) {
        super(
            elementRef,
            focusTrapFactory,
            document,
            dialogConfig,
            checker,
            ngZone,
            overlayRef,
            focusMonitor,
        );
        this.listenToViewportChange();
    }

    protected override _contentAttached(): void {
        super._contentAttached();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.windowRef.visualViewport?.removeEventListener('resize', this.viewportResizeListener);
    }

    private viewportResizeListener = (): void => {
        this._maxHeight = this.windowRef.visualViewport?.height || 0;
    };

    private listenToViewportChange(): void {
        this.windowRef.visualViewport?.addEventListener('resize', this.viewportResizeListener);
    }
}
