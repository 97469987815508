import { LabeledValue, Languages } from '@floori-web-components';

export interface ProductFilterOptionKeyValues {
    key: string;
    value: string;
    label: string;
}

export enum ProductFilterType {
    dynamic,
    static,
}

export interface FilterCondition {
    label: string;
    value: string[];
    filterValueType: string;
}

export interface ProductFilterKey {
    label: string;
    fieldName: string;

    [key: string | Languages]: string;
}

export enum ProductFilterValueType {
    single = 'SINGLE',
    array = 'ARRAY',
}

export interface ProductFilterDefinitionDto {
    key: ProductFilterKey;
    label: string;
    options: string[];
    optionsTranslations?: { [key: string]: { [key: string]: string } };
    optionsKeyed?: LabeledValue[];
    enableCondition: boolean | FilterCondition;
    selectedOption?: string[];
    filterOptionKeyValues: Array<ProductFilterOptionKeyValues>;
    isChildRelation: boolean;
    filterValueType: ProductFilterValueType;
    filterType: ProductFilterType;
    parentRelation: string;
    filterParent?: string;
    routeName: string;
}
